import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPolicyPage } from "../../store/slice/settingSlice";
import Loader from "../../component/Common/Loader";
import styled from "styled-components";
import { parse } from 'date-fns';
import { parseISO, format } from 'date-fns';
// Styled Components (moved outside for better organization)
const MainContainer = styled.main`
 font-family: 'Arial', sans-serif;
 color: #333;
`;

const BannerSection = styled.div`
  background-color: #a88547;
  color: #fff;
  padding: 4rem 0;
  text-align: center;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/images/privacy-banner-bg.svg');
    background-size: cover;
    opacity: 0.2;
    z-index: 0;
  }

  > * {
    position: relative;
    z-index: 1;
  }
`;

const BannerHeading = styled.h2`
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const BannerParagraph = styled.p`
  font-size: 1.1rem;
  color: #f0f0f0;
  margin-bottom: 1rem;
`;

const BannerUpdateDate = styled.span`
  font-size: 0.9rem;
  font-style: italic;
`;

const ContentSection = styled.section`
  background-color: #f5f6f7;
  padding: 3rem 0;
`;

const ContentContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  h1, h2, h3, h4, h5, h6 {
    color: #a88547;
    margin-bottom: 1rem;
  }

  p {
    line-height: 1.6;
    margin-bottom: 1.2rem;
  }

  ul, ol {
    padding-left: 2rem;
    margin-bottom: 1.2rem;
  }

  li {
    margin-bottom: 0.5rem;
  }

  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const ToggleContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 10;
`;

const ToggleLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
`;

const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: #f5f6f7;
  }

  &:checked + span:before {
    transform: translateX(28px);
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f5f6f7;
  transition: 0.4s;
  border-radius: 30px;

  &:before {
    content: "";
    position: absolute;
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: #a88445;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const LanguageLabel = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  font-weight: bold;
  color: #a88445;
  pointer-events: none;
`;

const NoPolicyText = styled.p`
  font-size: 1rem;
  color: #777;
  text-align: center;
`;

const PrivacyPolicyPage = () => {
  const dispatch = useDispatch();
  const policyList = useSelector((state) => state.setting.policyList); // Get the entire array
  const loading = useSelector((state) => state.setting.loader);

  const [plainTextPolicy, setPlainTextPolicy] = useState("");
  const [extractedPlainText, setExtractedPlainText] = useState(false);
  const [policyContent, setPolicyContent] = useState(""); // State to store extracted HTML content
  const [language, setLanguage] = useState(localStorage.getItem("language") || "en");

  useEffect(() => {
    dispatch(getPolicyPage());
  }, [dispatch]);

  useEffect(() => {
    const englishPolicy = policyList?.find(
      (policy) => policy.page_type === "privacy_policy" && policy.language === language
    );
  
    if (englishPolicy && englishPolicy.page_detail) {
      try {
        const pageDetail = JSON.parse(englishPolicy.page_detail);
        let extractedText = pageDetail.text || "";
  
        // ✅ Remove <pre> tags
        extractedText = extractedText.replace(/<pre>/g, '').replace(/<\/pre>/g, '');
  
        // ✅ Decode HTML entities
        const decodedText = extractedText
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&quot;/g, "\"")
          .replace(/&#39;/g, "'")
          .replace(/&amp;/g, "&");
  
        setPolicyContent(decodedText);
      } catch (error) {
        console.error("Error parsing policy detail JSON:", error);
        setPolicyContent("");
      }
    }
  }, [policyList, language]);
  

  useEffect(() => {
    if (policyContent && !extractedPlainText) {
      const plainText = policyContent.replace(/<[^>]*>/g, '');
      setPlainTextPolicy(plainText);
      setExtractedPlainText(true);
      console.log("Extracted Plain Text Policy", plainText);
    }
  }, [policyContent, extractedPlainText]);

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
  
    try {
      const parsedDate = parseISO(dateString); 
      return format(parsedDate, 'MMMM dd, yyyy');
    } catch (error) {
      console.error("Error formatting date:", error);
      return "N/A";
    }
  };
  
  // Find the English policy to get the updated_at date
  const selectedTerm = policyList?.find((policy) => policy.page_type === "privacy_policy" && policy.language === language);
  const updatedAt = selectedTerm?.updated_at;
  console.log("englishPolicy", selectedTerm);
  console.log("updatedAt", updatedAt);

  const languageChangeHandler = () => {
    const newLang = language === "en" ? "th" : "en";
    setLanguage(newLang);
    localStorage.setItem("language", newLang);
  };

  return (
    <MainContainer>
      <BannerSection>
        <BannerHeading>Privacy Policy</BannerHeading>
        {/* <BannerParagraph>The boring, but important stuff</BannerParagraph> */}
        {/* <BannerUpdateDate>
          Updated {formatDate(updatedAt)}
        </BannerUpdateDate> */}
      </BannerSection>

      <ContentSection>
        <ContentContainer>
          <ToggleContainer>
            <ToggleLabel>
              <ToggleInput
                type="checkbox"
                onChange={languageChangeHandler}
                checked={language === "th"}
              />
              <Slider />
              <LanguageLabel style={{ left: "8px" }}>EN</LanguageLabel>
              <LanguageLabel style={{ right: "8px" }}>TH</LanguageLabel>
            </ToggleLabel>
          </ToggleContainer>

          {loading ? (
            <Loader />
          ) : policyContent && policyContent.trim() !== "" ? (
            <div className="html-preview-container">
              <div dangerouslySetInnerHTML={{ __html: policyContent }} />
            </div>
          ) : (
            <p style={{ textAlign: "center", color: "#777", fontSize: "1rem" }}>
              No content available.
            </p>
          )}
        </ContentContainer>
      </ContentSection>
    </MainContainer>
  );
};

export default PrivacyPolicyPage;